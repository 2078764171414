function Home() {
  return (
    <div>
      <div className="container text-center my-3">
        <img className="logo" src="/logo512.png" />
        <h1 className="title my-3">n Music</h1>
        <div className="mb-4 close-lines">
          <p><a className="link-light" href="https://etherscan.io/address/0xadea332258a8927b2fdbcbec96f826c11d583fdf">Contract</a></p>
          <p><a className="link-light" href="https://opensea.io/collection/n-music">OpenSea</a></p>
        </div>
        <div className="mb-4 close-lines">
          <a href="https://opensea.io/collection/n-music" className="img-link">
            <img className="feature-image" src="/screenshot.png" />
          </a>
        </div>
        <div className="mb-4 close-lines">
          <p>n Music is the musical version of an n in C major. The notes of an n Music map to the values of an n. Meaning n Music #1 is the sound of n #1. A value of 1 is C4. A value of 14 is B5. A value of 0 is a rest. All n Musics are unique. 8888 are reserved for n Holders <strong>for 0.02 ETH</strong>. 1111 are available to the public <strong>for 0.03 ETH</strong>.</p>
          <p>The n Music smart contract forked <a className="link-light" href="https://github.com/TSnark/n-pass">n-pass</a> by <a className="link-light" href="https://opensea.io/collection/n-project">The n project</a>.</p>
          <p>I'll explain how n Music was made, and if you like, you can mint via the contract.</p>
        </div>
        <div className="mb-4 close-lines">
          <p className="text-decoration-underline">Audio</p>
          <p>1. Generated a dump of all values for the original 8888 ns + 1111 extra for the public into a list.</p>
          <p>2. Generated and recorded original synth audio using <a className="link-light" href="https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API">Web Audio API</a>.</p>
          <p>3. Split the synth audio into 14 sounds, mapped to the n values, plus silence for a rest.</p>
          <p>4. Wrote a script to concatenate the sounds using ffmpeg based on the n values. Meaning n Music #1 is the sound of n #1.</p>
        </div>
        <div className="mb-4 close-lines">
          <p className="text-decoration-underline">Visual</p>
          <p>1. Used <a className="link-light" href="https://github.com/WebReflection/linkedom">linkedom</a> to re-create the DOM in node.</p>
          <p>2. Customized <a className="link-light" href="https://github.com/paulrosen/abcjs">abcjs</a> to work with linkedom, and used it to generate the sheet music as an SVG for each n Music.</p>
        </div>
        <p className="mb-4">Check it out on OpenSea before you mint so you can see what it looks (and sounds) like.</p>
        <p className="mb-4"><a className="link-light" href="https://twitter.com/nmusic__">twitter.com/nmusic__</a></p>
      </div>
    </div>
  );
}

export default Home;
